import * as React from "react"
import PressRelease from "../components/PressReleases/PressRelease"
import Layout from "../components/Layout"


function PressReleasePage({ data }) {
  return (
    <Layout>
      <PressRelease/>
    </Layout>
    
  )
}

export default PressReleasePage




