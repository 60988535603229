import React, { useState } from 'react';
import { graphql, useStaticQuery } from "gatsby";
import "../scss/PressRelease.scss";
import leena from './leena press release.png';
import email from "./email_black.png"; 
import download from "./download.png"; 
import { Helmet } from "react-helmet";

export default function PublicSpeaking() {
    const data = useStaticQuery(graphql`
    query MyQuery1050 {
      allContentfulHeroImage(filter: {id: {eq: "883e2d6d-ddc6-5719-94f6-a0e0f4840903"}}) {
        edges {
          node {
            id
            socialMediaIcons {
              linkedInIcon {
                image {
                  file {
                    url
                  }
                }
              }
              facebookIcon {
                image {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  
  
  return (
    <>
    <Helmet>
    <title>Press Releases</title>
    <meta name="description" content="Media Enquiries - press@thefreshkid.com" />
    <meta name="keywords" content="women in leadership, diversity and inclusion" />
    <h1>First Diversity and Inclusion in Luxury Leadership Report</h1>
  </Helmet>
    <div className="pressReleaseContainer">
        <p className="pressReleaseTitle">Press Releases</p>
        <p className="pressReleaseYear">2023</p>
        <div className="rectangleBox">
            <div className='mediaEnquiries'><strong>Media Enquiries</strong></div>
            <div className='pressEmail'>press@thefreshkid.com</div>
        </div>
        <div className='date'>January 25th, 2024</div>
        <div className='firstPressRelease'>First Diversity and Inclusion in Luxury Leadership Report</div>


        <div className="mainContainer">
        <div className="imageContainer">
          <a href="https://thefreshkid.com/press-releases/luxury-diversity-and-inclusion/">
            <img className="lee" src={leena} alt="Leena Press Release" />
          </a>
        </div>
            <div className="SocialMediaPR">
                <div className="SharePR">Share</div>
                <a href="https://www.linkedin.com/shareArticle?mini=true&url=thefreshkid.com/the-fresh-kid/">
                <img
                    className="SocialIconPR"
                    alt=""
                    key=""
                    src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.linkedInIcon.image.file.url}
                />
                </a>

                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthefreshkid.com/the-fresh-kid/" target="_blank">
                <img
                    className="SocialIconPR"
                    alt=""
                    key=""
                    src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.facebookIcon.image.file.url}
                />
                </a>

                <a href="mailto:?subject=From thefreshkid.com: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry&body=Check out this article: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry www.thefreshkid.com/the-fresh-kid/" target="_blank">
                <img
                    className="SocialIcon1PR"
                    alt=""
                    key=""
                    src={email}
                    style={{ marginRight: '100px' }}
                />
                </a>
                {/* <a href="https://assets.ctfassets.net/x5jqyuhgi4rx/icSVpvzFikDIjLQ6ZjXcy/3550eb8bb4d01110afaa8da13a72a0ed/The_Fashion_Tech_Report-_FINAL__2_.pdf" download="The_Fashion_Tech_Report.pdf">
                <img
                    className="SocialIcon1PR"
                    alt=""
                    key=""
                    src={download}
                    style={{ marginRight: '100px' }}
                />
                </a> */}
            </div>
        </div>
        <div className="imageLine"></div>
        {/* <div className="downloadButtonContainer">
            <a href="https://assets.ctfassets.net/x5jqyuhgi4rx/icSVpvzFikDIjLQ6ZjXcy/3550eb8bb4d01110afaa8da13a72a0ed/The_Fashion_Tech_Report-_FINAL__2_.pdf" download="The_Fashion_Tech_Report.pdf">
                Download PDF
            </a>
        </div> */}

  </div>
  </>
  )
}


